import axios from "axios";
import React, { useEffect, useState } from "react";
import BackgroundImage from "../../common/assets/background-dashboard.jpg";
import HeadingWithBeppoLogo from "../../common/label/headingWithBeppoLogo";
import LoadingIndicator from "../../reusable/loadingIndicator";
import { isMobile } from "react-device-detect";
import BeppoLogo from "../../common/assets/beppo-logo-119x47.svg";
import Style from "./emailVerification.module.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOADER } from "../../actions/types";
import { RootState } from "../../reducers";

interface EmailVerificationProps {
  match: any;
}

const EmailVerification = (props: EmailVerificationProps) => {
  const [hasError, setHasError] = useState(false);
  const { isLoading } = useSelector((state: RootState) => state.loader);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_LOADER,
      payload: true,
    });
    axios
      .put(
        `${process.env.REACT_APP_SERVICE_URL}/api/accounts/emailConfirmation/${props.match.params.id}`
      )
      .then(() => {})
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
  }, [props.match.params.id]);

  const renderContent = () => {
    if (isMobile) {
      return (
        <div className={Style.mainContainer}>
          <HeadingWithBeppoLogo />
          {!hasError ? (
            <p className={Style.absoluteMobilePosition}>
              Ihre E-Mail ist jetzt aktiv
            </p>
          ) : (
            <>
              <p className={Style.absoluteMobilePosition}>
                Ein Fehler ist aufgetreten.
              </p>
              <p className={Style.absoluteMobilePosition2}>
                Ihre E-Mail ist nicht aktiv.
              </p>
            </>
          )}
        </div>
      );
    }
    return (
      <div>
        <>
          <div
            className={Style.desktopBackground}
            style={{
              backgroundImage: `url("${BackgroundImage}")`,
            }}
          >
            <div>
              <img
                src={BeppoLogo}
                id="beppoLogo"
                alt="beppo-logo"
                height={50}
              />
            </div>
            {!hasError ? (
              <p className={Style.absoluteDesktopPosition}>
                Ihre E-Mail ist jetzt aktiv!
              </p>
            ) : (
              <p className={Style.absoluteDesktopPosition}>
                Ein Fehler ist aufgetreten.Ihre E-Mail ist nicht aktiv!
              </p>
            )}
          </div>
        </>
      </div>
    );
  };
  return !isLoading ? renderContent() : <LoadingIndicator />;
};

export default EmailVerification;
