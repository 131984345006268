import React, {MouseEventHandler, useContext} from "react";
import moment from "moment";
import PaymentButton from "../common/button/paymentButton";
import CreditCardWhite from "../common/assets/credit-cardWhite.svg";
import MoneyIcon from "../common/assets/money-icon.svg";
import Style from "./ticketsDetails.module.scss";
import {useTranslation} from "react-i18next";
import InfoModal from "./infoModal";
import {TicketStatus} from "../reducers/ticket";
import GenericMobileCard from "./general/genericMobileCard";
import {useHistory} from "react-router-dom";
import DownloadBlue from "../common/assets/downloadBlue.svg";
import Hammer from "../common/assets/hammer.svg";
import EntityDetail from "./general/entityDetail";
import {TicketDetailsContext} from "./TicketDetailsContext";

const FINAL_STATUSES: string[] = [TicketStatus.PAID, TicketStatus.STOPPED];

const TicketDetailsGasStationMobile = () => {
    const history = useHistory();
    const changeRoute = (path: string) => {
        history.push(path);
    };
    const linkStyle: React.CSSProperties = {
      textDecoration: "none"
    };
    const { t } = useTranslation();

    const {
        ticket,
        appeal,
        formattedTotalAmount,
        formattedAmountLabel,
        formattedPaidAmount,
        formattedPaidAmountLabel,
        payableBeforeMoment,
        setOpenPayment,
        datatransPaymentSuccessffullModalOpen,
        setDatatransPaymentSuccessffullModalOpen,
        datatransPaymentErrorModalOpen,
        setDatatransPaymentErrorModalOpen,
        downloadPaymentSlip,
    } = useContext(TicketDetailsContext);

    if (!ticket) {
        return null;
    }
    const openInNewWindow = (url: string): MouseEventHandler<HTMLAnchorElement> => {
      return (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const uniqueWindowName = `window_${Date.now()}`;
        window.open(url, uniqueWindowName, 'fullscreen=yes');
      };
    };
  return (
    <GenericMobileCard title={t("TicketDetails.OffenseGasStation")}>
      <EntityDetail
        value={ticket.number}
        title={t("TicketDetails.Reference number")}
      />
      <EntityDetail
        value={ticket.licensePlate}
        title={t("TicketDetails.License plate")}
      />
      <EntityDetail
        value={ticket.gasStationZone.name}
        title={t("TicketDetails.GasStationZoneName")}
      />
      <EntityDetail
        value={moment(ticket.date).format("DD.MM.YYYY")}
        title={t("TicketDetails.Violation date Mobile")}
      />
      <EntityDetail
        value={moment(ticket.date).format("H:mm")}
        title={t("TicketDetails.Violation time Mobile")}
      />
      <EntityDetail
        value={ticket.gasStationTicketDetails.pillar}
        title={t("TicketDetails.Pillar")}
      />
      <EntityDetail
        value={ticket.gasStationTicketDetails.fuel}
        title={t("TicketDetails.Fuel")}
      />
      <EntityDetail
        value={ticket.gasStationTicketDetails.fuelVolume}
        title={t("TicketDetails.FuelVolume")}
      />
      <EntityDetail value={formattedTotalAmount} title={formattedAmountLabel} />

      {ticket.gasStationZone!=null && ticket.paidAmount != null && ticket.paidAmount != 0 
      && <EntityDetail value={formattedPaidAmount} title={formattedPaidAmountLabel} />}
      
      <EntityDetail
        value={
          ticket.status === TicketStatus.PAID
            ? moment(ticket?.paymentDate).format("DD.MM.YYYY")
            : payableBeforeMoment
        }
        title={
          ticket.status === TicketStatus.PAID
            ? t("TicketDetails.Payment date")
            : t("TicketDetails.Payable before")
        }
      />
      {!FINAL_STATUSES.includes(ticket.status) ? (
        <div className={Style.formMobile__paragraphDesc}>
          {t<string>("TicketDetails.Text below.firstPartGasStation")}

          <span
            onClick={() => changeRoute("/evidence")}
            className={Style.formMobile__mobileLinks}
          >
            {t<string>("TicketDetails.Text below.secondPartGasStation")}
          </span>
          {t<string>("TicketDetails.Text below.thirdPartGasStation")}

          {!FINAL_STATUSES.includes(ticket.status) && !ticket.appeal && !appeal && (
            <>
              <span>{t<string>("TicketDetails.Text below.seventhPart")}</span>
              <span
                onClick={() => changeRoute("/complain")}
                className={Style.formMobile__mobileLinks}
              >
                {t<string>("TicketDetails.Text below.eightPart")}
              </span>
            </>
          )}
        </div>
      ) : (
        <div className={Style.formMobile__paragraphDesc}>
          {t(`TicketDetails.Text below.fifthPartGasStation.${ticket.status}`).toString()}
        </div>
      )}
      <div className={Style.formDesktop__paragraph}>
        {t<string>("TicketDetails.Privacy policy text.firstPart")}
        <a href= {t<string>("TicketDetails.Privacy policy text.firstLink") } style={linkStyle} onClick={openInNewWindow(t<string>("TicketDetails.Privacy policy text.firstLink"))}>
          <span
            className={Style.formDesktop__links}
          >
            {t<string>("TicketDetails.Privacy policy text.secondPart")}
          </span>
        </a>
        {t<string>("TicketDetails.Privacy policy text.thirdPart")}
        <a href= {t<string>("TicketDetails.Privacy policy text.secondLink") } style={linkStyle} onClick={openInNewWindow(t<string>("TicketDetails.Privacy policy text.secondLink"))}>
          <span
            className={Style.formDesktop__links}
          >
            {t<string>("TicketDetails.Privacy policy text.fourthPart")}
          </span>
        </a>
        {t<string>("TicketDetails.Privacy policy text.fifthPart") + "."}
      </div>
      {!FINAL_STATUSES.includes(ticket.status) && (
        <>
          <div className={Style.onlinePaymentButtonPosition}>
            <PaymentButton
              desktop={false}
              text={t("TicketDetails.Online payment")}
              imgSrc={CreditCardWhite}
              imgAlt="cardWhite"
              onClick={() => {
                setOpenPayment(true);
              }}
            />
            <div style={{ flex: 0.05 }}></div>
            <div
              className={Style.btnMobilePrimary}
              onClick={() => changeRoute("/bank-transfer")}
            >
              <img src={MoneyIcon} alt="credit-card" />
              <p className={Style.btnTextWhite}>
                {t<string>("TicketDetails.BankTransfer")}
              </p>
            </div>
          </div>
          <div className={Style.whiteButtonsMobile}>
            <div className={Style.whiteButtonsMobile__buttonsRowContainer}>
              <div
                className={Style.btnMobileWhite}
                onClick={() => downloadPaymentSlip()}
              >
                <img src={DownloadBlue} alt="download" />
                <p className={Style.btnTextBlue}>
                  {t<string>("TicketDetails.Download the payment slip mobile")}
                </p>
              </div>
              <div style={{ flex: 0.05 }}></div>
              <div
                className={Style.btnMobileWhite}
                onClick={() => changeRoute("/socarPhoto")}
              >
                <img src={Hammer} alt="shipping-fast" />
                <p className={Style.btnTextBlue}>
                  {t<string>("TicketDetails.socarTitle")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {datatransPaymentSuccessffullModalOpen && (
        <InfoModal
          open={datatransPaymentSuccessffullModalOpen}
          onClose={setTimeout(() => {
            setDatatransPaymentSuccessffullModalOpen(false);
            window.location.replace("/ticket-details");
          }, 4000)}
          title={t("InfoModals.Online payment successfull gas station title")}
          infoText={t("InfoModals.Online payment successfull gas station text")}
        />
      )}

      {datatransPaymentErrorModalOpen && (
        <InfoModal
          open={datatransPaymentErrorModalOpen}
          onClose={setTimeout(() => {
            setDatatransPaymentErrorModalOpen(false);
            window.location.replace("/ticket-details");
          }, 4000)}
          title={t("InfoModals.Online payment error title")}
          infoText={t("InfoModals.Online payment error text")}
        />
      )}
    </GenericMobileCard>
  );
};

export default TicketDetailsGasStationMobile;
