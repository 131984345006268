import axios from "axios";
import React, { useState } from "react";
import BackgroundImage from "../../common/assets/background-dashboard.jpg";
import LoadingIndicator from "../../reusable/loadingIndicator";
import { isMobile } from "react-device-detect";
import BeppoLogo from "../../common/assets/beppo-logo-119x47.svg";
import Style from "./resetPassword.module.scss";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import { useForm } from "react-hook-form";
import ModalConfirmPasswordReset from "./ModalConfirmPasswordReset";
import Button from "../../common/button/button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { SET_LOADER } from "../../actions/types";

const ResetPassword = () => {
  const { isLoading } = useSelector((state: RootState) => state.loader);
  const dispatch = useDispatch();

  const { register, watch, handleSubmit } = useForm();
  const newPassword = watch("newPassword");
  const confirmNewPassword = watch("confirmNewPassword");
  const [openConfirmPasswordResetModal, setOpenConfirmModal] = useState(false);
  const closeModal = () => {
    setOpenConfirmModal(false);
  };

  const shouldDisable = () => {
    if (
      newPassword === confirmNewPassword &&
      newPassword !== "" &&
      confirmNewPassword !== "" &&
      newPassword !== undefined &&
      confirmNewPassword !== undefined
    ) {
      return false;
    }
    return true;
  };

  const confirmResetPassword = async () => {
    if (newPassword === confirmNewPassword) {
      dispatch({
        type: SET_LOADER,
        payload: true,
      });
      const params = new URLSearchParams(window.location.search);
      const userId = params.get("userId");
      axios
        .put(
          `${process.env.REACT_APP_SERVICE_URL}/api/accounts/password/${userId}/confirm`,
          { password: newPassword },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {
          console.log(error);
        })

        .finally(() => {
          dispatch({
            type: SET_LOADER,
            payload: false,
          });
        });
    }
  };

  const resetPassword = () => {
    confirmResetPassword();
    setOpenConfirmModal(true);
  };

  const renderContent = () => {
    if (isMobile) {
      return (
        <div>
          <>
            <div
              className={Style.background}
              style={{
                backgroundImage: `url("${BackgroundImage}")`,
              }}
            >
              <div>
                <img
                  src={BeppoLogo}
                  id="beppoLogo"
                  alt="beppo-logo"
                  height={50}
                />
              </div>
              <div className={Style.mainContainerMobile}>
                <form onSubmit={handleSubmit(resetPassword)}>
                  <InputFieldWithLabel
                    label="Neues Passwort"
                    name="newPassword"
                    registerName="newPassword"
                    register={register}
                    style={{ marginRight: "5px" }}
                    required
                    type="password"
                  />
                  <InputFieldWithLabel
                    label="Bestätige neues Passwort"
                    name="confirmNewPassword"
                    registerName="confirmNewPassword"
                    register={register}
                    style={{ marginRight: "5px" }}
                    required
                    type="password"
                  />

                  <Button
                    disabled={shouldDisable()}
                    text="Bestätigen"
                    style={{ display: "flex", justifyContent: "center" }}
                  />
                </form>
              </div>
            </div>
            <ModalConfirmPasswordReset
              open={openConfirmPasswordResetModal}
              close={closeModal}
            />
          </>
        </div>
      );
    }
    return (
      <div>
        <>
          <div
            className={Style.background}
            style={{
              backgroundImage: `url("${BackgroundImage}")`,
            }}
          >
            <div>
              <img
                src={BeppoLogo}
                id="beppoLogo"
                alt="beppo-logo"
                height={50}
              />
            </div>

            <div className={Style.mainContainer}>
              <form onSubmit={handleSubmit(resetPassword)}>
                <InputFieldWithLabel
                  label="Neues Passwort"
                  name="newPassword"
                  registerName="newPassword"
                  register={register}
                  style={{ marginRight: "5px" }}
                  required
                  type="password"
                />
                <InputFieldWithLabel
                  label="Bestätige neues Passwort"
                  name="confirmNewPassword"
                  registerName="confirmNewPassword"
                  register={register}
                  style={{ marginRight: "5px" }}
                  required
                  type="password"
                />

                <Button
                  disabled={shouldDisable()}
                  // classNameWrapper={Style.buttonDesktop}
                  buttonStyle={{ width: "240px", marginTop: "20px" }}
                  text="Bestätigen"
                />
              </form>
            </div>
          </div>

          <ModalConfirmPasswordReset
            open={openConfirmPasswordResetModal}
            close={closeModal}
          />
        </>
      </div>
    );
  };
  return !isLoading ? renderContent() : <LoadingIndicator />;
};

export default ResetPassword;
