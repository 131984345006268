import {CountryCode} from "../reducers/ticket";
import {selectedLanguage} from "../i18next";

export const currencyCode = (countryCode: CountryCode) => {
    switch (countryCode) {
        case "DE":
            return "EUR";
        case "CH":
            return "CHF";
        default:
            return "";
    }
};

/**
 * Formats a number to a currency string, according to country and language rules.
 * N.B. In case of Switzerland, the locale is hardcoded to `de-CH` to allow dot to be displayed as decimal separator.
 * @param value - number to format
 * @param countryCode - country code to use for formatting
 * @return {string} - formatted currency string
 */
export const formatCurrency = (value: number, countryCode: CountryCode): string => {

    function fixUpChLocale() {
        if (countryCode === "CH") {
            return "de-CH";
        } else {
            return `${selectedLanguage}-${countryCode}`;
        }
    }

    const locale = fixUpChLocale();


    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};