import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {downloadSlip, payment} from "../actions/ticket";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import i18next, {selectedLanguage} from "../i18next"
import {CountryCode, Ticket} from "../reducers/ticket";
import {RootState} from "../reducers";
import {currencyCode, formatCurrency} from "../util/formatter";
import {useTranslation} from "react-i18next";
import {SET_LOADER} from "../actions/types";

interface TicketDetailsContextValue {
    ticket: Ticket | null;
    appeal: object | null;
    lang: string;
    isProduction: boolean;
    lightbox: boolean; // Replace with the appropriate type for lightbox
    showLightbox: (value: boolean) => void;
    onLoaded: () => void;
    onOpened: () => void;
    onCancelled: () => void;
    onError: (data: any) => void;
    totalAmount: number;
    formattedTotalAmount: string,
    formattedAmountLabel: string,
    formattedPaidAmount: string,
    formattedPaidAmountLabel: string,
    pendingAmount?: string,
    setPendingAmount: (value: string) => void;
    payableBeforeMoment: string | null;
    setOpenPayment: (value: boolean) => void;
    transaction: string | null;
    datatransPaymentSuccessffullModalOpen: boolean;
    setDatatransPaymentSuccessffullModalOpen: (value: boolean) => void;
    datatransPaymentErrorModalOpen: boolean;
    setDatatransPaymentErrorModalOpen: (value: boolean) => void;
    downloadPaymentSlip: () => void;
}



// Define the initial state or default values for the context
const initialState: TicketDetailsContextValue = {
    ticket: null,
    appeal: null,
    lang: "de",
    isProduction: false,
    lightbox: false,
    showLightbox: () => {},
    onLoaded: () => console.log("Loaded"),
    onOpened: () => console.log("Opened"),
    onCancelled: () => {},
    onError: () => {},
    totalAmount: 0,
    formattedTotalAmount: "0,00",
    formattedAmountLabel: "Outstanding Amount",
    formattedPaidAmount: "0,00",
    formattedPaidAmountLabel: "Paid Amount",
    payableBeforeMoment: null,
    setOpenPayment: () => {},
    setPendingAmount: () => {},
    transaction: null,
    datatransPaymentSuccessffullModalOpen: false,
    setDatatransPaymentSuccessffullModalOpen: () => {},
    datatransPaymentErrorModalOpen: false,
    setDatatransPaymentErrorModalOpen: () => {},
    downloadPaymentSlip: () => {},
};

export const TicketDetailsContext = createContext(initialState);

const paymentPeriodDate = (ticket: Ticket) => {
    let date = new Date(ticket.date);
    if (ticket.status === "PENDING") {
        date = new Date();
    }
    if (ticket.issueDate != null) {
        date = new Date(ticket.issueDate);
    }
    date.setDate(date.getDate() + ticket?.paymentPeriod);
    return date;
};

export const payableBefore = (ticket: Ticket) => {
    return ticket?.reminderDeadline
        ? moment(ticket?.reminderDeadline).format("DD.MM.YYYY")
        : moment(paymentPeriodDate(ticket)).format("DD.MM.YYYY")
};

interface TicketDetailsProviderProps {
    children: ReactNode;
}

export const TicketDetailsProvider: React.FC<TicketDetailsProviderProps> = ({ children }) => {
    let state: TicketDetailsContextValue;

    const ticket = useSelector((state: RootState) => state.ticket.ticket);
    const appeal = useSelector((state: RootState) => state.ticket.appeal);

    const dispatch = useDispatch();
    const [, setError] = useState("")
    const [, setOpenDownloadSlipInfoModal] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [lightbox, showLightbox] = useState(false);
    const [pendingAmount, setPendingAmount] = useState<string|undefined>();
    const [
        datatransPaymentSuccessffullModalOpen,
        setDatatransPaymentSuccessffullModalOpen,
    ] = useState(false);
    const [datatransPaymentErrorModalOpen, setDatatransPaymentErrorModalOpen] =
        useState(false);
    const lang = selectedLanguage || i18next.language;

    const isProduction = process.env.REACT_APP_DATATRANS_PRODUCTION_MODE === "true";

    const payableBeforeMoment = payableBefore(ticket);

    const totalAmount =
        ticket.gasStationZone != null
            ? (ticket.totalGasStationAmount - ticket?.paidAmount)
            : ticket.status === "PAID"
                ? ticket.paidAmount
                    ? 0
                    : ticket?.amount
                : ticket?.reminderDeadline
                    ? (
                        ticket?.amount +
                        ticket?.reminderFee +
                        ticket?.additionalCost -
                        ticket?.paidAmount
                    )
                    : (ticket?.amount - ticket?.paidAmount);

    useEffect(() => {
        if (openPayment) {
            startPayment();
        }
    }, [openPayment]);

    useEffect(() => {
        if (window.location.href.includes("paymentSuccessfull")) {
            setDatatransPaymentSuccessffullModalOpen(true);
        }
        if (window.location.href.includes("paymentError")) {
            setDatatransPaymentErrorModalOpen(true);
        }
    }, []);

    useEffect(() => {
        if (!lightbox) {
            dispatch({
                type: SET_LOADER,
                payload: false,
            });
        }
    }, [lightbox]);

    const downloadPaymentSlip = async () => {
        dispatch(downloadSlip(ticket, lang, setOpenDownloadSlipInfoModal));
    };

    const startPayment = async () => {
        dispatch(
            payment(
                ticket,
                lang,
                setTransaction,
                setError,
                setOpenPayment,
                showLightbox,
                pendingAmount
            )
        );
    };

    const onCancelled = () => {
        console.log("Error:", "Cancel");
        showLightbox(false);
    };
    const onError = (data: any) => {
        console.log("Error with datatrans");
        console.debug(data);
        showLightbox(false);
    };

    const { t } = useTranslation();

    let formattedTotalAmount = initialState.formattedTotalAmount;
    let formattedAmountLabel = t("TicketDetails.Remaining amount");
    let formattedPaidAmount = initialState.formattedTotalAmount;
    let formattedPaidAmountLabel = t("TicketDetails.Paid Amount");    
    if (ticket && (ticket.gasStationZone || ticket.parkingZone)) {
        const isGasStationTicket = ticket?.gasStationZone != null;
        const countryCode: CountryCode = isGasStationTicket ? ticket.gasStationZone.countryCode : ticket.parkingZone.countryCode;
        formattedTotalAmount = formatCurrency(totalAmount, countryCode);
        formattedPaidAmount = formatCurrency(ticket.paidAmount, countryCode);
        const currencyCodeForCountry = currencyCode(countryCode);
        formattedAmountLabel = t("TicketDetails.Remaining amount") + " "
            + (currencyCodeForCountry ? `(${currencyCodeForCountry})` : ""); // add parenthesis around currencyCode
        formattedPaidAmountLabel = t("TicketDetails.Paid amount") + " "
        + (currencyCodeForCountry ? `(${currencyCodeForCountry})` : "");
    }

    state = {
        ...initialState,
        ticket,
        appeal,
        lang,
        isProduction,
        totalAmount,
        formattedTotalAmount,
        formattedAmountLabel,
        formattedPaidAmount,
        formattedPaidAmountLabel,
        payableBeforeMoment,
        setOpenPayment,
        setPendingAmount,
        onCancelled,
        onError,
        lightbox,
        transaction,
        datatransPaymentSuccessffullModalOpen,
        setDatatransPaymentSuccessffullModalOpen,
        datatransPaymentErrorModalOpen,
        setDatatransPaymentErrorModalOpen,
        downloadPaymentSlip
    };

    return (
        <TicketDetailsContext.Provider value={state}>
            {children}
        </TicketDetailsContext.Provider>
    );
};
