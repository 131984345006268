import React from "react";
import BeppoLogo from "../common/assets/beppo-logo-119x47.svg";
import LanguageMenu from "./languageMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Style from "./navbar.module.scss";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };
  const changeLanguageLabel = (label: string) => {
    setLanguage(label);
  };
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language.toUpperCase());
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light "
      style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        height: "80px",
      }}
    >
      <div className={`navbar-brand ${Style.navbarLogo}`}>
        <img src={BeppoLogo} id="beppoLogo" alt="beppo-logo" height={38} />
      </div>
      <div className={Style.languageSwitchContainer}>
        <div
          onClick={menu}
          className={Style.languageSwitchContainer__currentLanguage}
        >
          <FontAwesomeIcon icon={openMenu ? faAngleUp : faAngleDown} />
          <div>{language}</div>
        </div>
        {openMenu && (
          <LanguageMenu
            closeMenu={closeMenu}
            changeLanguageLabel={changeLanguageLabel}
          />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
